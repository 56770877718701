import React, { useContext, useEffect, useRef, useState } from 'react'
import axios from 'axios';
// import { AppContext } from '../Context/Context';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { basePath, monthData } from '../Constants/constant';
import BulkCVUploader from '../BulkCVUploader/BulkCVUploader';

const CvManegement = () => {
    // const { isUpload, setIsUpload } = useContext(AppContext);
    const tableRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [allCvReport, setAllCvReport] = useState([]);
    const [excelFile, setExcelFile] = useState(false);
    const [cvFile, setCvFile] = useState(false);
    const [cvUploadView, setCvUploadView] = useState(false);
    const [bulkCvUploadView, setBulkCvUploadView] = useState(false);
    const [excelUploadView, setexcelUploadView] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingFilter, setLoadingFilter] = useState(true);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [loadingUploadCv, setLoadingUploadCv] = useState(false);
    const [loadingUploadExcel, setLoadingUploadExcel] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isFilterOn, setIsFilterOn] = useState(false);
    const [allPages, setAllPages] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [inputPage, setInputPage] = useState(1);
    const [isChange, setIsChange] = useState(false);
    const tableContainerRef = useRef(null); // Add a ref to the table container
    const [filterData, setFilterData] = useState({
        uploadStatus: "1",
        candidateName: "",
        jobTitle: "",
        phone: "",
        email: "",
        gender: "",
        skills: "",
        summary: "",
        industry: "",
        currentCompany: "",
        currentDesignation: "",
        currentLocation: "",
        experience: "",
        ugDegree: "",
        ugSpecialization: "",
        pgDegree: "",
        pgSpecialization: "",
        functionArea: "",
        preferredLocation: "",
        annualSalary: "",
        noticePeriod: "",
        age: "",
        workPermit: "",
        maritalStatus: "",
        fromDate: "",
        toDate: "",
        dobFromDate: "",
        dobToDate: "",
        orderBy: "uploaded_at",
        orderDirection: "DESC"
    });
    const notify = (msg = "successfull!!") => toast.success(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });
    const notifyInfo = (msg) => toast.info(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });
    const notifyFillTheFields = (msg = "There is some issue!!") => toast.error(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });

    async function getCvData() {
        try {
            setLoading(true);
            let res = await axios.get(`${basePath}/getCVs?limit=${limit}&page=${page}`);
            console.log("=====>>>>>>", res.data);
            setAllCvReport(res?.data?.report);
            setTotalRecords(res?.data?.totalRecords);
            setAllPages(res?.data?.pages);
            // tableContainerRef.current.scrollTop = 0; // Reset scroll position
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }
    async function getCvDataFiltered() {
        try {
            setLoading(true);
            let res = await axios.get(`${basePath}/getCVs?limit=${limit}&page=${page}&filter=${JSON.stringify(filterData)}`);
            console.log("=====>>>>>>", res.data);
            setAllCvReport(res?.data?.report);
            setTotalRecords(res?.data?.totalRecords);
            setAllPages(res?.data?.pages);
            // tableContainerRef.current.scrollTop = 0; // Reset scroll position
            setLoading(false);
            setLoadingFilter(false);
            console.log("filterData====>>>>> 1 ",filterData);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }
    useEffect(() => {
        if (!loadingFilter && isChange) {
            getCvDataFiltered();
            setIsChange(false);
        }
    }, [isChange]);

    useEffect(() => {
        setLoadingFilter(true);
        console.log("filterData====>>>>> 2 ",filterData);
        getCvDataFiltered();
    }, [limit, page]);


    const handleCvSort = (columnName) => {
        if (filterData?.orderBy === columnName) {
            if (filterData?.orderDirection === "DESC") {
                setFilterData({ ...filterData, orderBy: columnName, orderDirection: 'ASC' });
            }
            else {
                setFilterData({ ...filterData, orderBy: columnName, orderDirection: 'DESC' });
            }
        }
        else {
            setFilterData({ ...filterData, orderBy: columnName, orderDirection: 'ASC' });
        }
    }

    const downloadButton = async (canId, candidateName) => {
        setLoadingDownload(canId);
        try {
            const response = await fetch(`${basePath}/downloadcv/${canId}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${candidateName.replace(/ /g, "_")}_resume_${new Date().toISOString().split("T")[0]?.split("-")[2]}-${monthData[parseInt(new Date().toISOString().split("T")[0]?.split("-")[1])]}-${new Date().toISOString().split("T")[0]?.split("-")[0]}.pdf`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url); // Cleanup the URL object
            notify(`Resume Dowmloaded Succesfully!!`)
            setLoadingDownload(false);
        } catch (error) {
            console.error('There was a problem with the download:', error);
            if (error?.status === 404) {
                notifyFillTheFields(error?.response?.error);
            }
            else {
                notifyFillTheFields("There was a problem with the download!! Please try again later.");
            }
            setLoadingDownload(false);
        }
    }
    // const downloadButton = async (canId, candidateName) => {
    //     setLoadingDownload(canId);
    //     try {
    //         const response = await fetch(`${basePath}/downloadresume/${canId}`);
    //         if (!response.ok) {
    //             throw new Error('Network response was not ok');
    //         }
    //         const blob = await response.blob();
    //         const url = window.URL.createObjectURL(blob);
    //         const a = document.createElement('a');
    //         a.href = url;
    //         a.download = `${candidateName.replace(/ /g, "_")}_resume_${new Date().toISOString().split("T")[0]?.split("-")[2]}-${monthData[parseInt(new Date().toISOString().split("T")[0]?.split("-")[1])]}-${new Date().toISOString().split("T")[0]?.split("-")[0]}.pdf`;
    //         document.body.appendChild(a);
    //         a.click();
    //         document.body.removeChild(a);
    //         window.URL.revokeObjectURL(url); // Cleanup the URL object
    //         setLoadingDownload(false);
    //     } catch (error) {
    //         console.error('There was a problem with the download:', error);
    //         if (error?.status === 404) {
    //             notifyFillTheFields(error?.response?.error);
    //         }
    //         else {
    //             notifyFillTheFields("There was a problem with the download!! Please try again later.");
    //         }
    //         setLoadingDownload(false);
    //     }
    // }

    const handleUploadExcel = () => {
        setLoadingUploadExcel(true);
        let data = {
            file: excelFile
        }
        const options = {
            url: `${basePath}/importexcel`,
            method: "POST",
            headers: {
                Accept: "application/json",
                'Content-Type': 'multipart/form-data',
            },
            data: data,
        }
        axios(options)
            .then((response) => {
                if (response.status == 200) {
                    setLoadingUploadExcel(false);
                    setCvFile(null);
                    setexcelUploadView(false);
                    getCvDataFiltered();
                    notify("Excel Uploaded Successfully");
                    console.log("==========>>>>>>>>response", response);
                }
                else {
                    setLoadingUploadExcel(false);
                    notifyFillTheFields(response?.data?.error || "Sorry!! Unable to import excel due to server issue.");
                }
            })
            .catch((e) => {
                setLoadingUploadExcel(false);
                console.log(e);
                notifyFillTheFields(e.response?.data?.error || "There is a network or server issue!!");
            });
    }
    const handleUploadCv = (id) => {
        setLoadingUploadCv(true);
        let data = {
            resume: cvFile
        }
        const options = {
            url: `${basePath}/uploadresume/${id}`,
            method: "PATCH",
            headers: {
                Accept: "application/json",
                'Content-Type': 'multipart/form-data',
            },
            data: data,
        }
        axios(options)
            .then((response) => {
                if (response.status == 200) {
                    setLoadingUploadCv(false);
                    setCvFile(null);
                    setCvUploadView(false);
                    getCvData();
                    notify("CV Uploaded Successfully");
                    console.log("==========>>>>>>>>response", response);
                }
                else {
                    setLoadingUploadCv(false);
                    notifyFillTheFields("Sorry!! Unable to import excel due to server issue. Please Try Again Later.");
                }
            })
            .catch((e) => {
                setLoadingUploadCv(false);
                console.log(e);
                notifyFillTheFields("There is a network or server issue!! Please Try Again Later.");
            });
    }
    const handleFilter = () => {
        if(!loadingFilter){
            getCvDataFiltered();
        }
        
    }


  
    // Function to handle table scroll
    const handleScroll = () => {
      if (tableRef.current) {
        setScrollPosition(tableRef.current.scrollLeft);
      }
    };
  
    // Function to restore scroll position
    const restoreScrollPosition = () => {
      if (tableRef.current) {
        tableRef.current.scrollLeft = scrollPosition;
      }
    };
  
    useEffect(() => {
      if (tableRef.current) {
        tableRef.current.addEventListener('scroll', handleScroll);
      }
      return () => {
        if (tableRef.current) {
          tableRef.current.removeEventListener('scroll', handleScroll);
        }
      };
    }, [allCvReport]);
  
    useEffect(() => {
      restoreScrollPosition();
    }, [allCvReport]); // Add data as a dependency so it restores position after data changes

    return (
        <div className='lightBabyGreen baseHeightWidth customScroll mb-5'>
            {/* <header className='darkPurple py-4 text-white sticky top-0 left-0'>
                <header className=' max-w-[85%] m-auto flex justify-between item-center' >
                    <div>
                        <img className=' w-[9rem]' src="/img/tcoLogo2.png" alt="" />
                    </div>
                    
                </header>
            </header> */} 
            {bulkCvUploadView && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 transition-opacity duration-300 ease-in-out">
                <div className="relative bg-white rounded-2xl shadow-2xl max-w-md w-full sm:max-w-lg md:max-w-2xl mx-auto p-6 overflow-hidden">
                  {/* Top-right close button with hover effect */}
                  <button
                    onClick={() => setBulkCvUploadView(!bulkCvUploadView)}
                    className="absolute top-2 right-2 bg-red-500 text-white rounded-[10px] p-2 hover:bg-red-600 hover:scale-110 transition-all duration-300 ease-in-out"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
            
                  <div
                    className="max-h-[80vh] overflow-y-auto p-4"
                    style={{
                      scrollbarWidth: 'thin', // For Firefox
                      scrollbarColor: 'rgba(0, 0, 0, 0.2) #f3f3f3', // For Firefox
                    }}
                  >
                    {/* Inline WebKit Scrollbar Styling */}
                    <style>
                      {`
                        .max-h-[80vh]::-webkit-scrollbar {
                          width: 8px;
                        }
            
                        .max-h-[80vh]::-webkit-scrollbar-thumb {
                          background-color: rgba(0, 0, 0, 0.2);
                          border-radius: 10px;
                        }
            
                        .max-h-[80vh]::-webkit-scrollbar-track {
                          background-color: #f3f3f3;
                        }
                      `}
                    </style>
            
                    {/* Your BulkCVUploader Component */}
                    <BulkCVUploader getCvData={getCvData} setBulkCvUploadView={setBulkCvUploadView} bulkCvUploadView={bulkCvUploadView} />
                  </div>
                </div>
              </div>
            )}




            <div className=''>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                    transition={Bounce}
                />
                <div className='welcomeBarHeight babyGreen smoothTransition textDarkPurple px-10 rounded-3xl flex items-center justify-between navAnimation  m-auto'>
                    <div className=' text-left'>
                        <h1 className=' text-5xl font-bold'>CV Management</h1>
                        <p className=' text-gray-500 font-semibold'>Ready to start your day</p>
                    </div>
                    <img className=' w-44' src="/img/registration.png" alt="registration" />
                </div>
                <div className=' my-3 text-left flex items-center gap-3'>
                    <button onClick={() => { setexcelUploadView(true); }} className=' py-2 px-5 rounded-lg bg-green-700 text-white font-semibold'>Excel Import</button>
                    <button onClick={() => { setIsFilterOn(!isFilterOn); }} className=' py-2 px-5 rounded-lg bg-blue-700 text-white font-semibold'>Filter</button>
                    <button onClick={() => { setBulkCvUploadView(!bulkCvUploadView); }} className=' py-2 px-5 rounded-lg bg-blue-700 text-white font-semibold'>Bulk File Upload</button>
                </div>
                {cvUploadView ?
                    <div onClick={(e) => { setCvUploadView(false); }} className=' w-full h-screen fixed left-0 top-0 z-20 flex items-center justify-center blurBack'>
                        <div onClick={(e) => { e.stopPropagation(); }} className=' bg-white overflow-auto mx-10 mb-5 px-10 py-5 rounded-3xl '>
                            <h1 className='text-center text-xl font-semibold py-2 text-blue-950 mb-4'>Upload Candidate CV</h1>
                            <h1 className=' text-left mb-2'>Candidate Name: <span className=' text-blue-800 font-semibold '>{allCvReport[parseInt(cvUploadView)]?.cand_name}</span></h1>
                            <div className=' flex flex-col justify-center items-center gap-5'>
                                <input onChange={(e) => { setCvFile(e.target.files[0]); }} className=' border-2 rounded-lg px-3 py-1' accept='.pdf' type="file" />
                                {!loadingUploadCv ?
                                    <button onClick={() => {
                                        if (cvFile) {
                                            handleUploadCv(allCvReport[parseInt(cvUploadView)]?.id);
                                        } else {
                                            notifyInfo("Please choose resume file!!")
                                        }
                                    }} className=" darkPurple text-white font-semibold rounded-lg px-4 text-sm py-1 mx-2 hoverGreen">Upload</button> :
                                    <button className=" darkPurple text-white font-semibold rounded-lg px-4 text-sm py-1 mx-2">Loading...</button>
                                }
                            </div>
                        </div>
                    </div> :
                    null}
                {excelUploadView ?
                    <div onClick={(e) => {
                        if (!loadingUploadExcel) {
                            setexcelUploadView(false);
                        }
                    }} className=' w-full h-screen fixed left-0 top-0 z-20 flex items-center justify-center blurBack'>
                        <div onClick={(e) => { e.stopPropagation(); }} className=' bg-white overflow-auto mx-10 mb-5 px-10 py-5 rounded-3xl '>
                            <h1 className='text-center text-xl font-semibold py-2 text-blue-950 mb-5'>Excel Import</h1>
                            <div className=' flex flex-col justify-center items-center gap-5'>
                                <input onChange={(e) => { setExcelFile(e.target.files[0]); }} className=' border-2 rounded-lg px-3 py-1' accept='.xlsx' type="file" />
                                {!loadingUploadExcel ?
                                    <button onClick={() => {
                                        if (excelFile) {
                                            handleUploadExcel();
                                        } else {
                                            notifyInfo("Please choose excel file!!")
                                        }
                                    }} className=" py-1 px-5 rounded-lg bg-green-700 text-white font-semibold hoverGreen">Upload</button> :
                                    <button className=" py-1 px-5 rounded-lg bg-green-900 text-white font-semibold ">Loading...</button>}
                            </div>
                        </div>
                    </div> :
                    null}
                    {isFilterOn ?
                        <div className='navAnimation flex flex-wrap items-center gap-x-3 gap-y-1 grayBlurShadow bg-white p-3 mt-5 rounded-xl'>
                            {/* <h1>Date Filter</h1> */}
                            <div className='flex flex-col justify-start items-start '>
                                <label >CV Upload Status</label>
                                <select value={filterData?.uploadStatus}
                                    className=' border-2 rounded-lg px-2 py-1 w-full'
                                    onChange={e => setFilterData({ ...filterData, uploadStatus: e.target.value })}>
                                    <option value="1">All</option>
                                    <option value="2">Uploaded</option>
                                    <option value="3">Not Uploaded</option>
                                </select>
                            </div>
                            <div className='flex items-center gap-3'>
                                <div className=' flex flex-col justify-start items-start'>
                                    <label>From: </label>
                                    <input
                                        className=' border-2 rounded-lg px-2 py-1'
                                        type="date"
                                        value={filterData?.fromDate}
                                        onChange={e => setFilterData({ ...filterData, fromDate: e.target.value })}
                                    />
                                </div>
                                <div className=' flex flex-col justify-start items-start'>
                                    <label>To: </label>
                                    <input
                                        className=' border-2 rounded-lg px-2 py-1'
                                        type="date"
                                        value={filterData?.toDate}
                                        onChange={e => setFilterData({ ...filterData, toDate: e.target.value })}
                                    />
                                </div>
                            </div>

                            <div className='flex flex-col justify-start items-start mb-2'>
                                <label >Job Title</label>
                                <input
                                    value={filterData?.jobTitle}
                                    className=' border-2 rounded-lg px-2 py-1'
                                    onChange={e => { if(e.target.value != "&")setFilterData({ ...filterData, jobTitle: e.target.value }); }}
                                    type="text" />
                            </div>
                            <div className='flex flex-col justify-start items-start mb-2'>
                                <label >Candidate</label>
                                <input
                                    value={filterData?.candidateName}
                                    className=' border-2 rounded-lg px-2 py-1'
                                    onChange={e => { if(e.target.value != "&")setFilterData({ ...filterData, candidateName: e.target.value }); }}
                                    type="text" />
                            </div>

                            <div className='flex flex-col justify-start items-start mb-2'>
                                <label >Email</label>
                                <input
                                    value={filterData?.email}
                                    className=' border-2 rounded-lg px-2 py-1'
                                    onChange={e => {if(e.target.value != "&")setFilterData({ ...filterData, email: e.target.value })}}
                                    type="text" />
                            </div>
                            <div className='flex flex-col justify-start items-start mb-2'>
                                <label >Mobile</label>
                                <input
                                    value={filterData?.phone}
                                    className=' border-2 rounded-lg px-2 py-1'
                                    onChange={e => setFilterData({ ...filterData, phone: e.target.value })}
                                    type="text" />
                            </div>
                            <div className='flex flex-col justify-start items-start mb-2'>
                                <label >Current Location</label>
                                <input
                                    value={filterData?.currentLocation}
                                    className=' border-2 rounded-lg px-2 py-1'
                                    onChange={e => { if(e.target.value != "&")setFilterData({ ...filterData, currentLocation: e.target.value }); }}
                                    type="text" />
                            </div>
                            <div className='flex flex-col justify-start items-start mb-2'>
                                <label >Preferred Location</label>
                                <input
                                    value={filterData?.preferredLocation}
                                    className=' border-2 rounded-lg px-2 py-1'
                                    onChange={e => { if(e.target.value != "&")setFilterData({ ...filterData, preferredLocation: e.target.value }); }}
                                    type="text" />
                            </div>
                            <div className='flex flex-col justify-start items-start mb-2'>
                                <label >Experience</label>
                                <input
                                    value={filterData?.experience}
                                    className=' border-2 rounded-lg px-2 py-1'
                                    onChange={e => {
                                        let num = `${e.target.value}`;
                                        if (num?.length < 3) {
                                            setFilterData({ ...filterData, experience: e.target.value });
                                        }
                                    }}
                                    type="number" />
                            </div>
                            <div className='flex flex-col justify-start items-start mb-2'>
                                <label >Current Company</label>
                                <input
                                    value={filterData?.currentCompany}
                                    className=' border-2 rounded-lg px-2 py-1'
                                    onChange={e => { if(e.target.value != "&")setFilterData({ ...filterData, currentCompany: e.target.value }); }}
                                    type="text" />
                            </div>
                            <div className='flex flex-col justify-start items-start mb-2'>
                                <label >Current Designation</label>
                                <input
                                    value={filterData?.currentDesignation}
                                    className=' border-2 rounded-lg px-2 py-1'
                                    onChange={e => { if(e.target.value != "&")setFilterData({ ...filterData, currentDesignation: e.target.value }); }}
                                    type="text" />
                            </div>
                            <div className='flex flex-col justify-start items-start mb-2'>
                                <label >Function Area</label>
                                <input
                                    value={filterData?.functionArea}
                                    className=' border-2 rounded-lg px-2 py-1'
                                    onChange={e => { if(e.target.value != "&")setFilterData({ ...filterData, functionArea: e.target.value }); }}
                                    type="text" />
                            </div>
                            <div className='flex flex-col justify-start items-start mb-2'>
                                <label >Industry</label>
                                <input
                                    value={filterData?.industry}
                                    className=' border-2 rounded-lg px-2 py-1'
                                    onChange={e => { if(e.target.value != "&")setFilterData({ ...filterData, industry: e.target.value }); }}
                                    type="text" />
                            </div>
                            <div className='flex flex-col justify-start items-start mb-2'>
                                <label >Skills</label>
                                <input
                                    value={filterData?.skills}
                                    className=' border-2 rounded-lg px-2 py-1'
                                    onChange={e => { if(e.target.value != "&")setFilterData({ ...filterData, skills: e.target.value }); }}
                                    type="text" />
                            </div>
                            <div className='flex flex-col justify-start items-start mb-2'>
                                <label >Annual Salary</label>
                                <input
                                    value={filterData?.annualSalary}
                                    className=' border-2 rounded-lg px-2 py-1'
                                    onChange={e => {
                                        let num = `${e.target.value}`;
                                        if (num?.length <= 3) {
                                            setFilterData({ ...filterData, annualSalary: e.target.value });
                                        }
                                    }}
                                    type="number" />
                            </div>
                            <div className='flex flex-col justify-start items-start mb-2'>
                                <label >Notice Period</label>
                                <input
                                    value={filterData?.noticePeriod}
                                    className=' border-2 rounded-lg px-2 py-1'
                                    onChange={e => {
                                        let num = `${e.target.value}`;
                                        if (num?.length <= 3) {
                                            setFilterData({ ...filterData, noticePeriod: e.target.value });
                                        }
                                    }}
                                    type="number" />
                            </div>
                            <div className='flex flex-col justify-start items-start mb-2'>
                                <label >Summary</label>
                                <input
                                    value={filterData?.summary}
                                    className=' border-2 rounded-lg px-2 py-1'
                                    onChange={e => { if(e.target.value != "&")setFilterData({ ...filterData, summary: e.target.value }); }}
                                    type="text" />
                            </div>

                            <div className='flex flex-col justify-start items-start mb-2'>
                                <label >UG Degree</label>
                                <input
                                    value={filterData?.ugDegree}
                                    className=' border-2 rounded-lg px-2 py-1'
                                    onChange={e => {if(e.target.value != "&")setFilterData({ ...filterData, ugDegree: e.target.value })}}
                                    type="text" />
                            </div>
                            <div className='flex flex-col justify-start items-start mb-2'>
                                <label >UG Specialization</label>
                                <input
                                    value={filterData?.ugSpecialization}
                                    className=' border-2 rounded-lg px-2 py-1'
                                    onChange={e => {if(e.target.value != "&")setFilterData({ ...filterData, ugSpecialization: e.target.value })}}
                                    type="text" />
                            </div>
                            <div className='flex flex-col justify-start items-start mb-2'>
                                <label >PG Degree</label>
                                <input
                                    value={filterData?.pgDegree}
                                    className=' border-2 rounded-lg px-2 py-1'
                                    onChange={e => {if(e.target.value != "&")setFilterData({ ...filterData, pgDegree: e.target.value })}}
                                    type="text" />
                            </div>
                            <div className='flex flex-col justify-start items-start mb-2'>
                                <label >PG Specialization</label>
                                <input
                                    value={filterData?.pgSpecialization}
                                    className=' border-2 rounded-lg px-2 py-1'
                                    onChange={e => {if(e.target.value != "&")setFilterData({ ...filterData, pgSpecialization: e.target.value })}}
                                    type="text" />
                            </div>
                            <div className='flex flex-col justify-start items-start mb-2'>
                                <label >Marital Status</label>
                                <input
                                    value={filterData?.maritalStatus}
                                    className=' border-2 rounded-lg px-2 py-1'
                                    onChange={e => {if(e.target.value != "&")setFilterData({ ...filterData, maritalStatus: e.target.value })}}
                                    type="text" />
                            </div>
                            <div className='flex flex-col justify-start items-start mb-2'>
                                <label >Work Permit</label>
                                <input
                                    value={filterData?.workPermit}
                                    className=' border-2 rounded-lg px-2 py-1'
                                    onChange={e => {if(e.target.value != "&")setFilterData({ ...filterData, workPermit: e.target.value })}}
                                    type="text" />
                            </div>
                            <div className='flex flex-col justify-start items-start mb-2'>
                                <label >Age</label>
                                <input
                                    value={filterData?.age}
                                    className=' border-2 rounded-lg px-2 py-1'
                                    onChange={e => {
                                        let num = `${e.target.value}`;
                                        if (num?.length < 3) {
                                            setFilterData({ ...filterData, age: e.target.value });
                                        }
                                    }}
                                    type="number" />
                            </div>
                            <div className='flex items-center gap-3'>
                                <div className=' flex flex-col justify-start items-start'>
                                    <label>DOB From: </label>
                                    <input
                                        className=' border-2 rounded-lg px-2 py-1'
                                        type="date"
                                        value={filterData?.dobFromDate}
                                        onChange={e => setFilterData({ ...filterData, dobFromDate: e.target.value })}
                                    />
                                </div>
                                <div className=' flex flex-col justify-start items-start'>
                                    <label>DOB To: </label>
                                    <input
                                        className=' border-2 rounded-lg px-2 py-1'
                                        type="date"
                                        value={filterData?.dobToDate}
                                        onChange={e => setFilterData({ ...filterData, dobToDate: e.target.value })}
                                    />
                                </div>
                            </div>
                            <div className='flex gap-3 justify-start items-start'>
                                <button onClick={()=>{
                                    setPage(1);
                                    setInputPage(1);
                                    setIsChange(true);
                                    }} 
                                    disabled={loadingFilter} className=' darkPurple cursor-pointer rounded-full py-2 px-3 text-white flex items-center gap-2 font-bold grayBlurShadow hoverGreen navAnimation'>Apply filter</button>
                                <button onClick={() => {
                                    setFilterData({
                                        uploadStatus: "",
                                        candidateName: "",
                                        jobTitle: "",
                                        phone: "",
                                        email: "",
                                        gender: "",
                                        skills: "",
                                        summary: "",
                                        industry: "",
                                        currentCompany: "",
                                        currentDesignation: "",
                                        currentLocation: "",
                                        experience: "",
                                        ugDegree: "",
                                        ugSpecialization: "",
                                        pgDegree: "",
                                        pgSpecialization: "",
                                        functionArea: "",
                                        preferredLocation: "",
                                        annualSalary: "",
                                        noticePeriod: "",
                                        age: "",
                                        workPermit: "",
                                        maritalStatus: "",
                                        fromDate: "",
                                        toDate: "",
                                        dobFromDate: "",
                                        dobToDate: "",
                                        orderBy: "uploaded_at",
                                        orderDirection: "DESC"
                                    });
                                    setIsChange(true);
                                    // setIsClearFilter(true);
                                    // fetchData();
                                }}  disabled={loadingFilter} className=' text-red-700 bg-red-200  py-2 px-3 rounded-lg hoverRed'>Clear Filter</button>
                                {/* <div className=' text-left w-fit'>
                    <button onClick={handleExport} className=' py-2 px-3 bg-green-800 text-white rounded-lg '>{loadingExport ? "Exporting..." : "Excel"}</button>
                </div> */}
                            </div>
                        </div> : null
                    }
                <section className="bg-white navAnimation grayBlurShadow px-7 pt-7 rounded-3xl mb-8 mt-5 " >
                    {!loading ?
                        Array.isArray(allCvReport) && allCvReport?.length >= 1 ?
                            <section className='bg-white overflow-auto  '>
                                <div ref={tableRef} className='overflow-auto tableBody min-h-[50vh] max-h-[77vh]'>
                                    <table className=' rounded-2xl baseWidth p-5 text-left'>
                                        <thead className='text-lg tableHead'>
                                            <tr className='bg-gray-200'>
                                                <th className='whitespace-nowrap text-center'>Sr.No.</th>
                                                <th className='whitespace-nowrap text-center'>Resume</th>
                                                <th className='whitespace-nowrap text-center'>
                                                    <div onClick={() => handleCvSort("uploaded_at")} className='flex item-center gap-2 justify-center w-full cursor-pointer'>
                                                        Upload Date
                                                        {filterData?.orderBy === "uploaded_at" && filterData?.orderDirection === "ASC" ?
                                                            <img className='w-4 h-4 mt-1' src="/img/upArrow.png" alt="up" /> :
                                                            filterData?.orderBy === "uploaded_at" && filterData?.orderDirection === "DESC" ?
                                                                <img className='w-4 h-4 mt-1' src="/img/downArrow.png" alt="up" /> :
                                                                <img className='w-4 h-4 mt-1' src="/img/upDownArrow.png" alt="up" />}
                                                    </div></th>
                                                <th style={{ minWidth: "13rem" }} className='whitespace-nowrap'>
                                                    <div onClick={() => handleCvSort("job_title")} className='flex item-center gap-2 justify-center w-full cursor-pointer'>
                                                        Job Title
                                                        {filterData?.orderBy === "job_title" && filterData?.orderDirection === "ASC" ?
                                                            <img className='w-4 h-4 mt-1' src="/img/upArrow.png" alt="up" /> :
                                                            filterData?.orderBy === "job_title" && filterData?.orderDirection === "DESC" ?
                                                                <img className='w-4 h-4 mt-1' src="/img/downArrow.png" alt="up" /> :
                                                                <img className='w-4 h-4 mt-1' src="/img/upDownArrow.png" alt="up" />}
                                                    </div></th>
                                                <th className='whitespace-nowrap'>
                                                    <div onClick={() => handleCvSort("cand_name")} className='flex item-center gap-2 justify-center w-full cursor-pointer'>
                                                        Candidate name
                                                        {filterData?.orderBy === "cand_name" && filterData?.orderDirection === "ASC" ?
                                                            <img className='w-4 h-4 mt-1' src="/img/upArrow.png" alt="up" /> :
                                                            filterData?.orderBy === "cand_name" && filterData?.orderDirection === "DESC" ?
                                                                <img className='w-4 h-4 mt-1' src="/img/downArrow.png" alt="up" /> :
                                                                <img className='w-4 h-4 mt-1' src="/img/upDownArrow.png" alt="up" />}
                                                    </div></th>
                                                <th className='whitespace-nowrap'>
                                                    <div onClick={() => handleCvSort("email")} className='flex item-center gap-2 justify-center w-full cursor-pointer'>
                                                        Email
                                                        {filterData?.orderBy === "email" && filterData?.orderDirection === "ASC" ?
                                                            <img className='w-4 h-4 mt-1' src="/img/upArrow.png" alt="up" /> :
                                                            filterData?.orderBy === "email" && filterData?.orderDirection === "DESC" ?
                                                                <img className='w-4 h-4 mt-1' src="/img/downArrow.png" alt="up" /> :
                                                                <img className='w-4 h-4 mt-1' src="/img/upDownArrow.png" alt="up" />}
                                                    </div></th>
                                                <th className='whitespace-nowrap'>
                                                    <div onClick={() => handleCvSort("phone")} className='flex item-center gap-2 justify-center w-full cursor-pointer'>
                                                        Mobile
                                                        {filterData?.orderBy === "phone" && filterData?.orderDirection === "ASC" ?
                                                            <img className='w-4 h-4 mt-1' src="/img/upArrow.png" alt="up" /> :
                                                            filterData?.orderBy === "phone" && filterData?.orderDirection === "DESC" ?
                                                                <img className='w-4 h-4 mt-1' src="/img/downArrow.png" alt="up" /> :
                                                                <img className='w-4 h-4 mt-1' src="/img/upDownArrow.png" alt="up" />}
                                                    </div></th>
                                                <th className='whitespace-nowrap'><div onClick={() => handleCvSort("current_location")} className='flex item-center gap-2 justify-center w-full cursor-pointer'>
                                                    Current Loaction
                                                    {filterData?.orderBy === "current_location" && filterData?.orderDirection === "ASC" ?
                                                        <img className='w-4 h-4 mt-1' src="/img/upArrow.png" alt="up" /> :
                                                        filterData?.orderBy === "current_location" && filterData?.orderDirection === "DESC" ?
                                                            <img className='w-4 h-4 mt-1' src="/img/downArrow.png" alt="up" /> :
                                                            <img className='w-4 h-4 mt-1' src="/img/upDownArrow.png" alt="up" />}
                                                </div></th>
                                                <th className='whitespace-nowrap'>
                                                    <div onClick={() => handleCvSort("preferred_location")} className='flex item-center gap-2 justify-center w-full cursor-pointer'>
                                                        Preferred Loaction
                                                        {filterData?.orderBy === "preferred_location" && filterData?.orderDirection === "ASC" ?
                                                            <img className='w-4 h-4 mt-1' src="/img/upArrow.png" alt="up" /> :
                                                            filterData?.orderBy === "preferred_location" && filterData?.orderDirection === "DESC" ?
                                                                <img className='w-4 h-4 mt-1' src="/img/downArrow.png" alt="up" /> :
                                                                <img className='w-4 h-4 mt-1' src="/img/upDownArrow.png" alt="up" />}
                                                    </div></th>
                                                <th className='whitespace-nowrap'>
                                                    <div onClick={() => handleCvSort("experience")} className='flex item-center gap-2 justify-center w-full cursor-pointer'>
                                                        Experience
                                                        {filterData?.orderBy === "experience" && filterData?.orderDirection === "ASC" ?
                                                            <img className='w-4 h-4 mt-1' src="/img/upArrow.png" alt="up" /> :
                                                            filterData?.orderBy === "experience" && filterData?.orderDirection === "DESC" ?
                                                                <img className='w-4 h-4 mt-1' src="/img/downArrow.png" alt="up" /> :
                                                                <img className='w-4 h-4 mt-1' src="/img/upDownArrow.png" alt="up" />}
                                                    </div></th>
                                                <th className='whitespace-nowrap'>
                                                    <div onClick={() => handleCvSort("current_company")} className='flex item-center gap-2 justify-center w-full cursor-pointer'>
                                                        Current Company
                                                        {filterData?.orderBy === "current_company" && filterData?.orderDirection === "ASC" ?
                                                            <img className='w-4 h-4 mt-1' src="/img/upArrow.png" alt="up" /> :
                                                            filterData?.orderBy === "current_company" && filterData?.orderDirection === "DESC" ?
                                                                <img className='w-4 h-4 mt-1' src="/img/downArrow.png" alt="up" /> :
                                                                <img className='w-4 h-4 mt-1' src="/img/upDownArrow.png" alt="up" />}
                                                    </div></th>
                                                <th className='whitespace-nowrap'>
                                                    <div onClick={() => handleCvSort("current_designation")} className='flex item-center gap-2 justify-center w-full cursor-pointer'>
                                                        Current Designation
                                                        {filterData?.orderBy === "current_designation" && filterData?.orderDirection === "ASC" ?
                                                            <img className='w-4 h-4 mt-1' src="/img/upArrow.png" alt="up" /> :
                                                            filterData?.orderBy === "current_designation" && filterData?.orderDirection === "DESC" ?
                                                                <img className='w-4 h-4 mt-1' src="/img/downArrow.png" alt="up" /> :
                                                                <img className='w-4 h-4 mt-1' src="/img/upDownArrow.png" alt="up" />}
                                                    </div></th>
                                                <th className='whitespace-nowrap'>
                                                    <div onClick={() => handleCvSort("func_area")} className='flex item-center gap-2 justify-center w-full cursor-pointer'>
                                                        Functional Area
                                                        {filterData?.orderBy === "func_area" && filterData?.orderDirection === "ASC" ?
                                                            <img className='w-4 h-4 mt-1' src="/img/upArrow.png" alt="up" /> :
                                                            filterData?.orderBy === "func_area" && filterData?.orderDirection === "DESC" ?
                                                                <img className='w-4 h-4 mt-1' src="/img/downArrow.png" alt="up" /> :
                                                                <img className='w-4 h-4 mt-1' src="/img/upDownArrow.png" alt="up" />}
                                                    </div></th>
                                                {/* <th className='whitespace-nowrap'>Role</th> */}
                                                <th>Industry</th>
                                                <th className='whitespace-nowrap'>Skills</th>
                                                <th className='whitespace-nowrap text-center'>
                                                    <div onClick={() => handleCvSort("annual_salary")} className='flex item-center gap-2 justify-center w-full cursor-pointer'>
                                                        Annual Salary
                                                        {filterData?.orderBy === "annual_salary" && filterData?.orderDirection === "ASC" ?
                                                            <img className='w-4 h-4 mt-1' src="/img/upArrow.png" alt="up" /> :
                                                            filterData?.orderBy === "annual_salary" && filterData?.orderDirection === "DESC" ?
                                                                <img className='w-4 h-4 mt-1' src="/img/downArrow.png" alt="up" /> :
                                                                <img className='w-4 h-4 mt-1' src="/img/upDownArrow.png" alt="up" />}
                                                    </div></th>
                                                <th className='whitespace-nowrap text-center'>Notice Period</th>
                                                <th className='whitespace-nowrap'>Summary</th>
                                                <th className='whitespace-nowrap'>UG Degree</th>
                                                <th className='whitespace-nowrap'>UG Specialization</th>
                                                <th className='whitespace-nowrap'>PG Degree</th>
                                                <th className='whitespace-nowrap'>PG Specialization</th>
                                                <th className='whitespace-nowrap'>Gender</th>
                                                <th className='whitespace-nowrap'>Marital Status</th>
                                                <th className='whitespace-nowrap'>Work Permit For USA</th>
                                                <th className='whitespace-nowrap text-center'>Date Of Birth</th>
                                                <th className='whitespace-nowrap text-center'>Age</th>
                                            </tr>
                                        </thead>
                                        <tbody className='tableBody'>
                                            {
                                                Array.isArray(allCvReport) && allCvReport?.map((val, idx) => {
                                                    return (
                                                        <tr key={idx} className=' relative backWhite font-semibold text-sm py-2'>
                                                            <td className='whitespace-nowrap text-center'>{idx + 1}</td>
                                                            <td>
                                                                {val?.resume ?
                                                                    loadingDownload===val?.id ?
                                                                    <button className=" darkPurple text-white font-semibold rounded-lg px-4 text-sm py-1 mx-2 ">Loading...</button> :
                                                                        <button onClick={() => { downloadButton(val?.id, val?.cand_name) }} className=" darkPurple text-white font-semibold rounded-lg px-4 text-sm py-1 mx-2 hoverGreen">Download</button> :
                                                                    <button onClick={() => { setCvUploadView(`${idx}`); }} className="  text-yellow-600 bg-yellow-200 hoverYellow font-semibold rounded-lg px-4 text-sm py-1 mx-2 whitespace-nowrap ">Upload CV</button>}

                                                            </td>
                                                            <td className='whitespace-nowrap text-center'>{val?.uploaded_at.split("T")[0].split("-")[2]} {monthData[parseInt(val?.uploaded_at.split("T")[0].split("-")[1])]} {val?.uploaded_at.split("T")[0].split("-")[0]}</td>
                                                            {/* <td className='whitespace-nowrap text-center'>
                                                            {val?.resume ?
                                                                !loadingDownload ?
                                                                    <button onClick={() => { downloadButton(val?.id, val?.cand_name) }} className=" darkPurple text-white font-semibold rounded-lg px-4 text-sm py-1 mx-2 hoverGreen">Download</button> :
                                                                    <button className=" darkPurple text-white font-semibold rounded-lg px-4 text-sm py-1 mx-2 ">Loading...</button> :
                                                                <button className=" darkPurple cursor-not-allowed opacity-70 text-white font-semibold rounded-lg px-4 text-sm py-1 mx-2 ">Download</button>
                                                            }
                                                        </td> */}
                                                            <td className={`${val?.job_title?.length > 30 ? "textEllipse" : "whitespace-nowrap"}`}>{val?.job_title === "NA" ? <div className='bg-gray-300 rounded-lg py-1 w-full text-gray-700 text-center text-xs'>NA</div> : val?.job_title}</td>
                                                            <td className={`${val?.cand_name?.length > 30 ? "textEllipse" : "whitespace-nowrap"}`}>{val?.cand_name === "NA" ? <div className='bg-gray-300 rounded-lg py-1 w-full text-gray-700 text-center text-xs'>NA</div> : val?.cand_name}</td>
                                                            <td className={`${val?.email?.length > 30 ? "textEllipse" : "whitespace-nowrap"}`}>{val?.email === "NA" ? <div className='bg-gray-300 rounded-lg py-1 w-full text-gray-700 text-center text-xs'>NA</div> : val?.email}</td>
                                                            <td className={`${val?.phone?.length > 30 ? "textEllipse" : "whitespace-nowrap"}`}>{val?.phone === "NA" ? <div className='bg-gray-300 rounded-lg py-1 w-full text-gray-700 text-center text-xs'>NA</div> : val?.phone}</td>
                                                            <td className={`${val?.current_location?.length > 30 ? "textEllipse" : "whitespace-nowrap"}`}>{val?.current_location === "NA" ? <div className='bg-gray-300 rounded-lg py-1 w-full text-gray-700 text-center text-xs'>NA</div> : val?.current_location}</td>
                                                            <td className={`${val?.preferred_location?.length > 30 ? "textEllipse" : "whitespace-nowrap"}`}>{val?.preferred_location === "NA" ? <div className='bg-gray-300 rounded-lg py-1 w-full text-gray-700 text-center text-xs'>NA</div> : val?.preferred_location}</td>
                                                            <td className='whitespace-nowrap text-center'>{val?.experience === "NA" ? <div className='bg-gray-300 rounded-lg py-1 w-full text-gray-700 text-center text-xs'>NA</div> : `${val?.experience} Yrs.`}</td>
                                                            <td className={`${val?.current_company?.length > 30 ? "textEllipse" : "whitespace-nowrap"}`}>{val?.current_company === "NA" ? <div className='bg-gray-300 rounded-lg py-1 w-full text-gray-700 text-center text-xs'>NA</div> : val?.current_company}</td>
                                                            <td className={`${val?.current_designation?.length > 30 ? "textEllipse" : "whitespace-nowrap"}`}>{val?.current_designation === "NA" ? <div className='bg-gray-300 rounded-lg py-1 w-full text-gray-700 text-center text-xs'>NA</div> : val?.current_designation}</td>
                                                            <td className={`${val?.func_area?.length > 30 ? "textEllipse" : "whitespace-nowrap"}`}>{val?.func_area === "NA" ? <div className='bg-gray-300 rounded-lg py-1 w-full text-gray-700 text-center text-xs'>NA</div> : val?.func_area}</td>
                                                            <td className={`${val?.industry?.length > 30 ? "textEllipse" : "whitespace-nowrap"}`}>{val?.industry === "NA" ? <div className='bg-gray-300 rounded-lg py-1 w-full text-gray-700 text-center text-xs'>NA</div> : val?.industry}</td>
                                                            <td className={`${val?.skills?.length > 30 ? "textEllipse " : "whitespace-nowrap"}`}>{val?.skills === "NA" ? <div className='bg-gray-300 rounded-lg py-1 w-full text-gray-700 text-center text-xs'>NA</div> : val?.skills?.split(",").join(", ")}</td>
                                                            <td className='whitespace-nowrap text-center'>{val?.annual_salary === "NA" ? <div className='bg-gray-300 rounded-lg py-1 w-full text-gray-700 text-center text-xs'>NA</div> : val?.annual_salary} LPA</td>
                                                            <td className='whitespace-nowrap text-center'>{val?.notice_period === "NA" ? <div className='bg-gray-300 rounded-lg py-1 w-full text-gray-700 text-center text-xs'>NA</div> : val?.notice_period} Days</td>
                                                            <td className={`${val?.summary?.length > 30 ? "textEllipse" : "whitespace-nowrap"}`}>{val?.summary === "NA" ? <div className='bg-gray-300 rounded-lg py-1 w-full text-gray-700 text-center text-xs'>NA</div> : val?.summary}</td>
                                                            <td className={`${val?.ug_degree?.length > 30 ? "textEllipse" : "whitespace-nowrap"}`}>{val?.ug_degree === "NA" ? <div className='bg-gray-300 rounded-lg py-1 w-full text-gray-700 text-center text-xs'>NA</div> : val?.ug_degree}</td>
                                                            <td className={`${val?.ug_spl?.length > 30 ? "textEllipse" : "whitespace-nowrap"}`}>{val?.ug_spl === "NA" ? <div className='bg-gray-300 rounded-lg py-1 w-full text-gray-700 text-center text-xs'>NA</div> : val?.ug_spl}</td>
                                                            <td className={`${val?.pg_degree?.length > 30 ? "textEllipse" : "whitespace-nowrap"}`}>{val?.pg_degree === "NA" ? <div className='bg-gray-300 rounded-lg py-1 w-full text-gray-700 text-center text-xs'>NA</div> : val?.pg_degree}</td>
                                                            <td className={`${val?.pg_spl?.length > 30 ? "textEllipse" : "whitespace-nowrap"}`}>{val?.pg_spl === "NA" ? <div className='bg-gray-300 rounded-lg py-1 w-full text-gray-700 text-center text-xs'>NA</div> : val?.pg_spl}</td>
                                                            <td className={`${val?.gender?.length > 30 ? "textEllipse" : "whitespace-nowrap"}`}>{val?.gender === "NA" ? <div className='bg-gray-300 rounded-lg py-1 w-full text-gray-700 text-center text-xs'>NA</div> : val?.gender}</td>
                                                            <td className={`${val?.marital_status?.length > 30 ? "textEllipse" : "whitespace-nowrap"}`}>{val?.marital_status === "NA" ? <div className='bg-gray-300 rounded-lg py-1 w-full text-gray-700 text-center text-xs'>NA</div> : val?.marital_status}</td>
                                                            <td className={`${val?.work_permit?.length > 30 ? "textEllipse" : "whitespace-nowrap"}`}>{val?.work_permit === "NA" ? <div className='bg-gray-300 rounded-lg py-1 w-full text-gray-700 text-center text-xs'>NA</div> : val?.work_permit}</td>
                                                            <td className='whitespace-nowrap text-center'>{val?.dob === "NA" ? <div className='bg-gray-300 rounded-lg py-1 w-full text-gray-700 text-center text-xs'>NA</div> : val?.dob}</td>
                                                            <td className='whitespace-nowrap text-center'>{val?.age === "NA" ? <div className='bg-gray-300 rounded-lg py-1 w-full text-gray-700 text-center text-xs'>NA</div> : val?.age}</td>

                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className='flex items-center justify-between pb-4'>
                                    <div className='flex items-center gap-2'>
                                        <div className='grayBlurShadow px-3 py-1'>
                                            <select value={limit} onChange={(e) => { setLimit(e.target.value); }}>
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="200">200</option>
                                                <option value="500">500</option>
                                            </select>
                                        </div>
                                        <div className='grayBlurShadow px-1 py-1 text-sm font-semibold'>
                                            <h1>Total Records: {totalRecords}</h1>
                                        </div>
                                        <div className='grayBlurShadow px-1 py-1 text-sm font-semibold'>
                                            <h1>Total Pages: {allPages}</h1>
                                        </div>
                                    </div>
                                    <div className='px-3 flex gap-1 items-center'>
                                        <h1>Search Page: </h1>
                                        <input value={inputPage} type="number" placeholder='' className='border-2 px-3 w-20' onChange={(e) => {
                                            const newPage = parseInt(e.target.value);
                                            setInputPage(newPage);
                                        }} />
                                        <button className=' darkPurple text-white font-semibold rounded-lg px-4 text-sm py-1 mx-2 hoverGreen' onClick={() => {
                                            if (inputPage > 0 && inputPage <= allPages) {
                                                setPage(inputPage);
                                            }
                                        }}>Search</button>
                                    </div>
                                    <div className='flex items-center grayBlurShadow px-3 py-1 rounded-lg'>
                                        <div onClick={() => {
                                            if (page > 1) {
                                                setPage(page - 1);
                                                setInputPage(page - 1);
                                            }
                                        }} className={`${page === 1 ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                            <img className='w-5' src="/img/leftArrow.png" alt="leftArrow" />
                                        </div>
                                        <div className='px-3'>
                                            {page}
                                        </div>
                                        <div onClick={() => {
                                            if (page < allPages) {
                                                setPage(page + 1);
                                                setInputPage(page + 1);
                                            }
                                        }} className={`${page == allPages ? "opacity-40 cursor-not-allowed" : "opacity-100 cursor-pointer"}`}>
                                            <img className='w-5' src="/img/rightArrow.png" alt="rightArrow" />
                                        </div>
                                    </div>
                                </div>
                            </section> :
                            <div className='navAnimation w-full flex flex-col justify-center items-center bg-white mt-5 py-5 mb-10 rounded-3xl '>
                                <img className='w-[25%]' src="/img/addReports.png" alt="" />
                                <h1 className='text-2xl font-bold'>Oops!! "<span className='text-blue-950'>No Resume Found !!</span>"</h1>
                            </div> :
                        <div className='h-[60vh] mb-5 w-full bg-white mt-5 rounded-3xl '>
                            <img className='m-auto w-1/4' src="/img/loader2.gif" alt="loading..." />
                        </div>
                    }
                </section>
            </div>
        </div >
    );
}

export default CvManegement;
